<template>
    <footer class="guidance-footer">{{$t('guidance.copy-right', {date: year})}}</footer>
</template>

<script>
export default {
    data() {
        return { 
            year: new Date().getFullYear()
        }
    }
}
</script>

<style scoped>
.guidance-footer{
    height: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222222;
    font-size: 12px;
}
</style>