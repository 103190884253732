<template>
  <div class="env-selecter-content">
    <div
      class="env-input"
      ref="env"
      :class="{ 'drop-fold-up': foldUp }"
      @click="foldUp = !foldUp"
    >
      {{ env }} <i class="arrow"></i>
    </div>
    <ul class="env-drop-list" v-show="foldUp">
      <li
        class="env-drop-list-item"
        v-for="(item, index) in displayList"
        :key="index"
       
        @click.stop="changeLangs(item.prop)"
      >
        <span>{{ $i18n.locale === item.prop ? '√' : '' }}</span
        >{{ $t(item.label) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    supportLangs: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      foldUp: false,
      removeEvt: null,
      list: [
        { label: 'guidance.env-type-3', prop: 'en' },
        { label: 'guidance.env-type-1', prop: 'cn' },
        { label: 'guidance.env-type-8', prop: 't-cn' },
        { label: 'guidance.env-type-2', prop: 'ko' },
        { label: 'guidance.env-type-4', prop: 'ru' },
        { label: 'guidance.env-type-5', prop: 'tur' },
        { label: 'guidance.env-type-6', prop: 'spa' },
        { label: 'guidance.env-type-7', prop: 'jp' },
        
      ],
    }
  },
  mounted() {
    this.removeEvt = this.bindBodyClickEvt('env-selecter-content', () => {
      const { $el } = this.$refs.env
      if (document.activeElement !== $el) {
        this.foldUp = false
      }
    })
  },
  beforeDestroy() {
    if (typeof this.removeEvt === 'function') {
      this.removeEvt()
    }
  },
  computed: {
    env() {
      const value = this.list.find(({ prop }) => prop === this.$i18n.locale)
      return value ? this.$t(value.label) : ''
    },
    displayList() {
      let list = [
        ...this.list
      ]
      if(Array.isArray(this.supportLangs)) {
        list = list.filter(({prop}) => this.supportLangs.includes(prop))
      }
      return list
    }
  },
  methods: {
    setEnv(type) {
      localStorage.setItem('env', type)
    },
    changeLangs(type) {
      this.$i18n.locale = type
      this.setEnv(type)
      this.foldUp = false
    },
    bindBodyClickEvt(className = '', callback = () => {}) {
      document.body.addEventListener('click', clickEvt)
      const $this = this
      function clickEvt(evt) {
        try {
          const includeEl = evt.target.getElementsByClassName(className)
          const TargetNode = document.querySelector(`.${className}`)
          if (
            (includeEl.length > 0 ||
              (TargetNode && !$this.isChildOf(TargetNode, evt.target))) &&
            typeof callback === 'function'
          ) {
            callback(evt.target)
          }
        } catch (e) {
          console.error(e)
        }
      }
      return function unsubscribe() {
        document.body.removeEventListener('click', clickEvt)
      }
    },
    isChildOf(parent, child) {
      let parentNode
      if (child && parent) {
        parentNode = child.parentNode
        while (parentNode) {
          if (parent === parentNode) {
            return true
          }
          parentNode = parentNode.parentNode
        }
      }
      return false
    },
  },
}
</script>

<style scoped>
.env-selecter-content {
  position: relative;
}
.env-input {
  min-width: 98px;
  height: 24px;
  padding: 0 5px 0 17px;
  box-sizing: border-box;
  border-radius: 2px 2px 2px 2px;
  background: rgba(0, 33, 136, 0.3);
  font-size: 14px;
  text-align: left;
  color: white;
  line-height: 24px;
  position: relative;
  user-select: none;
  cursor: pointer;
}
.arrow {
  width: 0;
  height: 0;
  border: 4px solid white;
  border-color: white transparent transparent transparent;
  border-bottom: none;
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 11px;
}
.drop-fold-up .arrow {
  transform: rotate(180deg);
}
.env-drop-list {
  position: absolute;
  top: 27px;
  right: 0;
  min-width: 153px;
  border-radius: 4px 4px 4px 4px;
  background: #f5f5f7eb;
  box-shadow: 0 6px 6px 0 #00000008;
  font-size: 14px;
  text-align: left;
  color: #222222;
  overflow: hidden;
}
.env-drop-list-item {
  height: 30px;
  display: flex;
  align-items: center;
}
.env-drop-list-item span {
  display: inline-block;
  width: 33px;
  padding-left: 13px;
  padding-right: 8px;
  color: #1e6fff;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
}
.env-drop-list-item:hover {
  cursor: pointer;
  background: #afbad3;
}
</style>
