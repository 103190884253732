import cnConfig from './langs/cn.json'
import enConfig from './langs/en.json'
import trandionalCnConfig from './langs/t-cn.json'
import koConfig from './langs/ko.json'
import ruConfig from './langs/ru.json'
import jpConfig from './langs/jp.json'
import turConfig from './langs/tur.json'
import spaConfig from './langs/spa.json'
export const i18nConfig = {
    cn: {
        ...cnConfig
    },
    en: {
        ...enConfig
    },
    't-cn': {
        ...trandionalCnConfig
    },
    ko: {
        ...koConfig
    },
    ru: {
        ...ruConfig
    },
    jp: {
        ...jpConfig
    },
    tur: {
        ...turConfig
    },
    spa: {
        ...spaConfig
    }
}