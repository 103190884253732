<template>
  <header class="guidance-header">
    <img src="@/assets/ACE-LOGO.png" />
    <env-select class="header-env-select" :support-langs="supportLangs"></env-select>
  </header>
</template>

<script>
import EnvSelect from './env-select'
export default {
  props: {
    supportLangs: {
      type: Array,
      default: null,
    },
  },
  components: {
    EnvSelect,
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.guidance-header {
  height: 48px;
  background: rgba(0, 24, 159, 0.1);
  display: flex;
  align-items: center;
}
img {
  height: 28px;
  margin-left: 20px;
}
.header-env-select {
  margin-left: auto;
  margin-right: 24px;
}
</style>
